@import "./breakpoints.scss";

$base-font-size: 1em;
$base-line-height: 1.5;
$header-line-height: 1.25;
$base-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

@include mob-xs {
  body {
    font-size: $base-font-size*0.8;
    line-height: $base-line-height*0.8;
  }
}

@include mob-sm {
  body {
    font-size: $base-font-size*0.9;
    line-height: $base-line-height*0.9;
  }
}

@include mob-md {
  body {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }
}

@include tab-lg-landscape {
  body {
    font-size: $base-font-size*0.8;
    line-height: $base-line-height*0.8;
  }
}

@include desk-xs {
  body {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }
}

@include desk-lg {
  body {
    font-size: $base-font-size*1.2;
    line-height: $base-line-height*1.2;
  }
}

@include desk-xl {
  body {
    font-size: $base-font-size*1.3;
  }
}

@include desk-xxl {
  body {
    font-size: $base-font-size*1.4;
  }
}
