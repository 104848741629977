@import "../../../assets/stylesheets/flexbox.scss";

.error-conainer {
  @extend .flex, .col, .center;
  margin: 4em 0;

  img {
    width: 100%;
    max-width: 40rem;
  }
}
