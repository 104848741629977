$max-layout-width: 100em;

$padding-layout-horz-xs: 1em;
$padding-layout-vert-xs: 1em;

$padding-layout-horz-sm: 2em;
$padding-layout-vert-sm: 2em;

$padding-layout-horz-md: 4em;
// $padding-layout-vert-md: 6em;

$padding-layout-horz-lg: 6em;
// $padding-layout-vert-lg: 10em;

$padding-layout-horz-xl: 12em;
// $padding-layout-vert-xl: 14em;

$padding-layout-horz-xxl: 16em;
// $padding-layout-vert-xxl: 20em;

$PageContent-padding-top: 1em;
