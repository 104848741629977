@import "./breakpoints.scss";
@import "./spacing.scss";
// @import "../../components/layouts/Header/_vars.scss";

.responsive-container {
  padding: 0;
}

.responsive-container-pad {
  padding: 1em;
}

@include desk-xxs {
  .responsive-container {
    padding-left: $padding-layout-horz-xs;
    padding-right: $padding-layout-horz-xs;
    // max-width: 100em;

    @include tab-sm {
      padding-left: $padding-layout-horz-sm;
      padding-right: $padding-layout-horz-sm;
    }

    @include tab-sm {
      padding-left: $padding-layout-horz-sm;
      padding-right: $padding-layout-horz-sm;
    }

    @include tab-lg-landscape {
      padding-left: $padding-layout-horz-xs;
      padding-right: $padding-layout-horz-xs;
    }

    @include desk-xs {
      padding-left: $padding-layout-horz-sm;
      padding-right: $padding-layout-horz-sm;
    }

    @include desk-sm {
      padding-left: $padding-layout-horz-md;
      padding-right: $padding-layout-horz-md;
    }

    @include desk-md {
      padding-left: $padding-layout-horz-lg;
      padding-right: $padding-layout-horz-lg;
    }

    @include desk-lg {
      padding-left: $padding-layout-horz-xl;
      padding-right: $padding-layout-horz-xl;
    }

    @include desk-xl {
      padding-left: calc((100vw - $max-layout-width) / 2);
      padding-right: calc((100vw - $max-layout-width) / 2);
    }
  }
}

.ShowPage {
  .LoadingContainer.fullPage, .NoContent.fullPage {
    justify-content: center;
    position: relative;
    top: auto;
    transform: inherit;
    // min-height: calc(100vh - #{$header-height-lg} - 2em - 2em);
  }

  .PageContent {
    align-items: center;
  }

  .SidebarRows {
    max-width: 80em;
  }
}
