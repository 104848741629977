@import "../../../assets/stylesheets/breakpoints.scss";
@import "../../../assets/stylesheets/colors.scss";
@import "../../../assets/stylesheets/flexbox.scss";
@import "../../../assets/stylesheets/_spacing.scss";

.PageContent {
  // @extend .flex, .col, .start, .ai-start;
  @extend .flex, .col, .center, .ai-start;
  flex: 1;
  height: 100%;
  // flex: 1;
  padding: $PageContent-padding-top 0;
  padding: 0;
  position: relative;
  width: 100%;
}
