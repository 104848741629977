@import "./fonts.scss";
@import "./colors.scss";
@import "./mixins.scss";
@import "./breakpoints.scss";
@import "./flexbox.scss";

* { @include border-box-sizing }

html, body {
  overflow-x: hidden;
}

html {
  background: $darker;
  color: $white;
  font-family: $base-font;
  font-size: $base-font-size;
  height: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  width: auto;
}

body {
  height: 100%;
}

a {
  color: darken($white, 15%);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $white;
  }
}

.AppPage {
  @extend .flex, .col, .center, .full;
  height: 100%;
  padding: 1rem;
  text-align: center;

  .AppPage__link {
    margin: 2rem 2rem 4rem;
    z-index: 1;
  }

  .AppPage__logo {
    width: 100%;
  }

  p {
    @include titleize;
  }

  .AppPage__downloads {
    @extend .flex, .row, .center, .full;
    margin-top: 2rem;

    img, svg {
      height: 4rem;
      width: auto;
    }
  }
}

.AnimatedRoutes {
  @extend .flex, .col, .center, .full;
  height: 100%;
}

button {
  @extend .flex, .col, .start, .ai-start;
  background: none;
  border: none;
  color: $white;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
}

p {
  line-height: 1.2;
  margin: 0;
}

em {
  text-decoration: none;
  font-style: normal;
}

:root {
  --toastify-color-error: #a20023;
  --toastify-color-info: #CACACA;
  --toastify-color-success: #a8ff00;
  --toastify-text-color-info: #222222;
  --toastify-text-color-success: #222222;
  --toastify-toast-min-height: 1rem;
}

.Toastify__toast--info, .Toastify__toast--success {
  .Toastify__close-button {
    color: #222222;
  }
}

@include tab-md {
  .AppPage {
    .AppPage__logo {
      width: 30rem;
    }
  }
}
