@import "../../../assets/stylesheets/breakpoints.scss";

.NinetyOrDiePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.NinetyOrDiePage {
    .video-js {
        width: 100%;
        height: auto;
        z-index: 0;

        overflow: hidden;

    }
    video {

        &:focus {
            outline: none;
        }
    }
}

@include tab-sm {
    .NinetyOrDiePage {
        padding: 0 2rem;

        .video-js {
            border-radius: 10px;
        }
    }
}

@include desk-xxs {
    .NinetyOrDiePage {
        padding: 0 4rem;
    }
}