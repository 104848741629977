@import "./breakpoints.scss";

.flex {
  display: flex;
}

.iflex {
  display: inline-flex;
}

.flex,
.iflex {
  flex-direction: column; // default to col

  &.col {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;

    &.ai-start {
      align-items: flex-start;
    }

    &.ai-end {
      align-items: flex-end;
    }

    &.ai-stretch {
      align-items: stretch;
    }
  }

  &.start {
    justify-content: flex-start;
    align-items: center;

    &.ai-start {
      align-items: flex-start;
    }

    &.ai-end {
      align-items: flex-end;
    }

    &.ai-stretch {
      align-items: stretch;
    }
  }

  &.end {
    justify-content: flex-end;
    align-items: center;

    &.ai-start {
      align-items: flex-start;
    }

    &.ai-end {
      align-items: flex-end;
    }

    &.ai-stretch {
      align-items: stretch;
    }
  }

  &.between {
    justify-content: space-between;
    align-items: center;

    &.ai-start {
      align-items: flex-start;
    }

    &.ai-end {
      align-items: flex-end;
    }

    &.ai-stretch {
      align-items: stretch;
    }
  }

  &.around {
    justify-content: space-around;
    align-items: center;

    &.ai-start {
      align-items: flex-start;
    }

    &.ai-end {
      align-items: flex-end;
    }

    &.ai-stretch {
      align-items: stretch;
    }
  }

  &.evenly {
    justify-content: space-around;
    align-items: center;

    &.ai-start {
      align-items: flex-start;
    }

    &.ai-end {
      align-items: flex-end;
    }

    &.ai-stretch {
      align-items: stretch;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.full {
    width: 100%;
  }
}

// MOBILE AND TABLET
.flex, .iflex {
  &.row {
    flex-direction: column;
  }

  &.row.pure {
    flex-direction: initial;
  }
}

.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
  width: 100%;
}

// DESKTOP
@include desk-xxs {
  .flex,
  .iflex {
    &.row {
      flex-direction: row;
    }
  }

  .col-12 {
    width: 100%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-9 {
    width: 75%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-6 {
    width: 50%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-3 {
    width: 25%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-1 {
    width: 8.33%;
  }
}
