@import "../../../assets/stylesheets/_flexbox.scss";
@import "../../../assets/stylesheets/_mixins.scss";

.HomePage {
  //@extend .flex, .col, .evenly, .full;
  @extend .flex, .col, .center, .full;
  height: 100%;
  padding: 6rem 0;
  position: relative;
  gap: 2rem;
  //justify-content: space-evenly;

  .HomePage__logo {
    width: 100%;
  }

  .HomePage__logo__main {
    width: 15em;
  }

  .HomePage__apps {
    @extend .flex, .row, .center, .pure, .wrap;
  }


  .HomePage__app {
    @extend .flex, .col, .center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.5em;
    border: 2px solid rgba(255, 255, 255, 0.05);
    color: $grey-light;
    font-size: 0.6rem;
    height: 10em;
    margin: 1em;
    padding: 1em;
    text-align: center;
    width: 10em;

    img {
      height: 5em;
      width: auto;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);

      color: $white;
      box-shadow: 0 -1px 0 0 $grey inset;
    }
  }

  .HomePage__app__title {
    @include titleize;
    margin: 1rem 0 0;
  }
}

//.button {
//  background-color: transparent;
//  border-color: #A1D341;
//  border-width: 2px;
//  border-style: solid;
//  color: #A1D341;
//  padding: 10px 20px;
//  font-weight: bold;
//  border-radius: 10px;
//  width: 200px;
//  justify-content: center;
//  display: flex;
//  align-items: center;
//  font-size: 25px;
//  transition: all 0.15s ease;
//
//  &:hover {
//    background-color: #A1D341;
//    color: black;
//  }
//}

.button {
  //background-color: transparent;
  //color: white;
  background-color: #ccc;
  color: black;
  border-color: #ccc;

  border-width: 2px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 10px;
  width: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 25px;
  transition: all 0.15s ease;

  &:hover {
    background-color: white;
    color: black;
    border-color: white;
  }
}


@include mob-md {
  .HomePage {
    padding: 4rem 0;

    .HomePage__app {
      font-size: 0.65rem;
    }
  }
}

@include tab-sm {
  .HomePage {
    justify-content: center;
    gap: 2rem;

    .HomePage__logo__main {
      //margin-bottom: 6em;
    }

    .HomePage__app {
      font-size: 1rem;
      margin: 0 1em;
    }

    .HomePage__apps {
      // bottom: 10%;
      // position: absolute;
    }
  }
}
