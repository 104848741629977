@import "../../../assets/stylesheets/breakpoints.scss";
@import "../../../assets/stylesheets/components.scss";
@import "../../../assets/stylesheets/_flexbox.scss";
@import "../../../assets/stylesheets/mixins.scss";
@import "../../../assets/stylesheets/spacing.scss";
// @import "../Menu/vars.scss";

$max-form-width: 40rem;
$max-col-width: 50rem;

.PageWrapper {
  @extend .responsive-container, .flex, .col, .start;
  // min-height: calc(100vh - $menu-height-mobile);

  &.PageWrapper--no-padding {
    padding: 0;
  }

  &.PageWrapper--padding {
    @extend .responsive-container-pad;
  }

  &.PageWrapper--col, &.PageWrapper--form {
    @extend .flex, .col, .start;
  }

  &.PageWrapper--adminForm {
    padding: 1em;
    width: 100%;

    .PageContent {
      align-items: center;
      max-width: $max-form-width;
      width: 100%;
    }
  }

  &.PageWrapper--col {
    .PageContent {
      max-width: $max-col-width;
    }
  }

  &.PageWrapper--form {
    @extend .responsive-container-pad;
    justify-content: center;

    .PageContent {
      max-width: $max-form-width;
    }
  }

  &.PageWrapper--center {
    justify-content: center;
  }
}

@include desk-xxs {
  .PageWrapper {
    // min-height: calc(100vh - $menu-height-mobile);
    padding-top: $padding-layout-vert-sm;
    padding-bottom: $padding-layout-vert-sm;

    &.PageWrapper--adminForm {
      padding: 0;

      .PageContent {
        padding: 2em 0;
        justify-content: flex-start;
      }
    }
  }
}
