@import "../../assets/stylesheets/_breakpoints.scss";
@import "../../assets/stylesheets/_colors.scss";
@import "../../assets/stylesheets/_flexbox.scss";
@import "../../assets/stylesheets/_mixins.scss";

.BackButton {
  @extend .flex, .row, .start, .pure;
  pointer-events: none;
  position: absolute;
  font-size: 0.75rem;
  left: 2em;
  top: 2em;

  a {
    @extend .flex, .row, .start, .pure;
    @include title-primary;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.5em;
    border-radius: 0.5em;
    border: 2px solid rgba(255, 255, 255, 0.05);
    color: $primary;
    padding: 1em;
    pointer-events: all;

    svg {
      height: 1em;
      margin-right: 1rem;
      width: 1em;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: $primary;
    }
  }
}

@include tab-sm {
  .BackButton {
    font-size: 1rem;
  }
}

@include desk-xxs {
  .BackButton {
    left: 2em;
    top: 2em;
  }
}
