@import "./breakpoints.scss";
@import "./colors.scss";
@import "./spacing.scss";

@mixin border-box-sizing {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin delayedEnterTransition($parent-class, $child-class, $elements) {
  @for $i from 0 through $elements {
    $transition: $i * 0.03;
    #{$parent-class} #{$child-class}:nth-child(#{$i + 1}):not([display=none]) {
      @include transition(transform 0.2s #{$transition}s ease-in-out, opacity 0.2s #{$transition}s ease-in-out);
    }
  }
}

@mixin ellipsis-clamp($clamp-value) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $clamp-value;
  display: -webkit-box;
  // font-size: 1em;
  line-clamp: $clamp-value;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fill-available-height {
  height: stretch;
  height: -webkit-fit-content;
}

@mixin fill-available-width {
  width: stretch;
}

@mixin font-face($family, $relative-path, $filename, $weight, $style) {
  @font-face {
    font-family: "#{$family}";
    src: font-url("#{$relative-path}#{$filename}.ttf");
    src: font-url("#{$relative-path}#{$filename}.ttf?#iefix") format("ttf");
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin title-primary {
  @include titleize;
  color: $primary;
}

@mixin title-primary-bold {
  @include titleize;
  color: $primary-bold;
}

@mixin title-secondary {
  @include titleize;
  color: $secondary;
}

@mixin titleize {
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

@mixin transform($block...) {
  -moz-transform: $block;
  -ms-transform: $block;
  -o-transform: $block;
  -webkit-transform: $block;
  transform: $block;
}

// $block... will ensure the arg is interpreted as one arg, regardless of commas
@mixin transition($block...) {
  -webkit-transition: $block;
  -moz-transition: $block;
  transition: $block;
}
