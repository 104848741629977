@import "../../assets/stylesheets/_colors.scss";

.Bubbles__content {
  background: -webkit-linear-gradient(darken(#6F40B6, 35%), black);
  height: 100vh;
  position: absolute;
  width: 100%;
  // box-shadow: 0 -2rem 10rem -6rem $primary inset;
}

.Bubbles__bottom {
  box-shadow: 0 0 3rem 0.2rem $primary;
  position: absolute;
  top: 100%;
  width: 100%;
}

.Bubbles {
  height: 100%;
  opacity: 0.5;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.Bubble {
  background: darken(#A1D341, 15%);
  border-radius: 50%;
  border: 1px solid $primary-bold;
  // border: 1px solid $grey-light;
  height: 6px;
  transition: 1s;
  width: 6px;
  will-change: transform;

  &:hover {
    background: white;
    // cursor: crosshair;
    // transform: scale(1.5);
  }

  .Bubble__light {
    position: absolute;
    left: 20%;
    top: 20%;
    background: lighten(white, 10%);
    border-radius: 50%;
    opacity: 0.4;
    box-shadow: 0 0 4px 4px lighten(white, 10%);
    // ba
  }
}


.Bubble__container {
  animation: bubbleRise 10s infinite ease-in;
  bottom: 0;
  position: absolute;
  will-change: transform;
}

@keyframes bubbleRise {
  0% {
    bottom: 0;
    opacity: 0.01;
  }
  10% {
    // bottom: 0;
    opacity: 0.1;
  }
  25% {
    margin-right: 1rem;
    margin-left: 0;
    opacity: 0.5;
  }
  50% {
    margin-left: 1rem;
    margin-right: 0;
    opacity: 0.85;
  }
  90% {
    margin-right: 1rem;
    margin-left: 0;
    // bottom: 80%;
    opacity: 0.25;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}

@keyframes spinall {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
